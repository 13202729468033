import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import Wheel from '../containers/wheel';
import Select from '../containers/select';
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption

} from 'reactstrap';

import { Pie, Polar } from 'react-chartjs-2';



const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
const colors = ['#E50E57', '#0E2BE5', '#EBE425', '#10EE10', '#000', '#fff'];
const colorNames = ['Red', 'Blue', 'Yellow', 'Green', 'White'];

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            colors: [],
            playerColors: [],
            currColor: null,
            players: null
        }
    }

    render() {


        let options = {
            options: this.state.colors,
            baseSize: Math.min(window.innerWidth, window.innerHeight) / 2 - 30,
            spinAngleStart: Math.random() * 10 + 10,
            spinTimeTotal: Math.random() * (this.state.colors.length) + 3 * 1000,
            onComplete: (val) => {
                console.log(val)
                let options = this.state.colors;
                let playerColors = this.state.playerColors;
                let currColor;
                let newArr = [];
                for (let i = 0; i < options.length; i++) {
                    if (i == val) {

                        for (let j = 0; j < colors.length; j++) {
                            if (colors[j] == options[i]) {
                                playerColors.push(j);
                                currColor = colors[j];
                                break;
                            }
                        }



                        console.log(playerColors);
                        this.setState({
                            playerColors
                        })
                        continue;
                    }
                    //newArr.push(options[i])
                }

                for (let i = 0; i < this.state.players; i++) {
                    if (currColor == colors[i]) {
                        continue;
                    }
                    newArr.push(colors[i]);
                }

                console.log(newArr);
                this.setState({
                    colors: newArr
                }, this.forceUpdate())

            }
        }

        let cName;
        for (let i = 0; i < colors.length; i++) {
            if (colors[i] == this.state.colors[0]) {
                cName = colorNames[i];
                break;
            }
        }

        const data = {
            gridLines: {
                display: false
            },

            labels: [
            ],
            datasets: [{
                data: this.state.colors.map((item) => { if (item != this.state.currColor) return 1; else return 0.9; }),
                backgroundColor: this.state.colors,
                hoverBackgroundColor: this.state.colors
            }]
        };


        console.table(this.state);
        return (
            <div>

                {this.state.players == null ?
                    <div className="select-players">
                        <Container>
                            <Row>
                                <Col lg="12">
                                    
                                    <h1>Startseite</h1>
                                    <div className="players">
                                        <div>
                                            <button onClick={() => {
                                        let options = [];
                                        for (let i = 0; i < 3; i++) {
                                            options.push(colors[i])
                                        }

                                        this.setState({ players: 3, colors: options });

                                    }}>3</button>
                                            <button onClick={() => {
                                        let options = [];
                                        for (let i = 0; i < 4; i++) {
                                            options.push(colors[i])
                                        }

                                        this.setState({ players: 4, colors: options });

                                    }}>4</button>
                                        </div>
                                        <div>
                                            <button onClick={() => {
                                        let options = [];
                                        for (let i = 0; i < 5; i++) {
                                            options.push(colors[i])
                                        }

                                        this.setState({ players: 5, colors: options });

                                    }}>5</button>
                                            <button onClick={() => {
                                        let options = [];
                                        for (let i = 0; i < 6; i++) {
                                            options.push(colors[i])
                                        }

                                        this.setState({ players: 6, colors: options });

                                    }}>6</button>
                                        </div>
                                    </div>
                                    <h2>Auswahl Spieler</h2>


                                </Col>
                            </Row>
                        </Container>
                    </div>
                    :
                    null
                }
                {this.state.players ?
                    <Container>
                        <Row>
                            <Col lg="12" className="wheel-wrap">
                                <h1>BATTLE BUTTON</h1>
                                <Polar options={{
                                    responsive: true,
                                    legend: { display: false },
                                    gridLines: {
                                        display: false
                                    },

                                    legend: {
                                        display: false
                                    },
                                    scale: {
                                        display: false
                                    }

                                }} data={data} legend={false} onElementsClick={(elems) => {
                                    console.log(elems)
                                    if (elems.length && elems[0]._model.backgroundColor != this.state.currColor) {
                                        this.setState({
                                            currColor: elems[0]._model.backgroundColor,

                                        }, () => {

                                            setTimeout(() => {
                                                let color;
                                                while (true) {
                                                    color = this.state.colors[Math.floor(Math.random() * this.state.players)];
                                                    if (color == this.state.currColor) {
                                                        continue;
                                                    } else {
                                                        break;
                                                    }
                                                }

                                                this.setState({
                                                    showColor: color
                                                }, () => {
                                                    setTimeout(() => {
                                                        this.setState({
                                                            showColor: null,
                                                            currColor: null
                                                        })
                                                    }, 3000);
                                                })
                                            }, 1000)


                                        })
                                        console.log(elems[0]);
                                    }
                                }} />


                            </Col>
                            {/*   <Col lg="12" className="table">
                                <table>
                                    <tr>
                                        <th>Player #</th>
                                        <th>Color</th>
                                    </tr>
                                    {
                                        this.state.playerColors.map((item, idx) => {
                                            return (
                                                <tr>
                                                    <td>{idx + 1}</td>
                                                    <td>{colorNames[item]}</td>
                                                </tr>
                                            )
                                        })
                                    }

                                    {
                                        this.state.colors.length == 1 ?

                                            <tr>
                                                <td>{this.state.players}</td>
                                                <td>{cName}</td>
                                            </tr>

                                            : null
                                    }
                                </table>

                                {this.state.playerColors.length >= this.state.players ?  

                                    <button onClick={() => this.setState({
                                        colors: [],
                                        playerColors: [],
                                        players: null,
                                        currColor: null

                                    })}>
                                        Restart
</button>
:
null
                                }
                            </Col>
                            */}
                        </Row>
                    </Container>
                    :
                    null

                }

                {
                    this.state.showColor ?

                        <div className="color-screen" style={{ backgroundColor: this.state.showColor }}>

                        </div>

                        :

                        null
                }




            </div>

        );
    }
}

export default Page(HomePage);